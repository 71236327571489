import { FC, useEffect, useState, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "components";
import { Button } from "components/Button";
import { ButtonSize } from "components/Button/Button.enums";
import { Checkbox } from "components/Checkbox";
import { Input } from "components/Input";
import Layout from "components/Layout";
import { Position } from "components/Map/Map.d";
import { Map } from "components/Map";
import { PhoneInput } from "components/PhoneInput";
import { Radio } from "components/Radio";
import { useForm } from "react-hook-form";
import {
  SizeContainer,
  StyledContainer,
  StyledFormContainer,
  StyledContentContainer,
  CheckboxContainer,
  ButtonContainer,
} from "./HouseApplication.styled";
import { houseApplicationValidationSchema } from "./validators";
import { useNavigate, useParams } from "react-router-dom";
import MultiImageInput from "components/MultiImageInput/MultiImageInput";
import {
  retrieveOwnerInvitation,
  sendHouseApplication,
} from "services/Invitation";
import { HouseApplicationForm } from "./HouseApplication.d";
import { isValidForm } from "utils/isValidForm";
import { ApplicationFormData } from "./types";
import { Textarea } from "components/Textarea";
import { useGooglePlaceAutoComplete } from "utils/useGooglePlaceAutoComplete";
import { toast } from "react-toastify";
import { Label } from "components/Label";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { HOUSES_PAGE, MAIN_PAGE } from "urls/frontend";

const defaultCenter: Position = {
  lat: 0,
  lng: 0,
};

const HouseApplication: FC = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<HouseApplicationForm>({
    resolver: yupResolver(houseApplicationValidationSchema),
    mode: "onSubmit",
  });

  const profile = useProfile();
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";

  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const [center, setCenter] = useState<Position>(defaultCenter);
  const { token } = useParams<{ token: string }>();
  const googleAutoComplete = useGooglePlaceAutoComplete();
  const addressRef = useRef<HTMLInputElement | null>(null);
  let autoComplete: google.maps.places.Autocomplete | null = null;

  const house_type = watch("house_type");
  const house_size = watch("house_size");
  const is_on_other_platforms = watch("is_on_other_platforms");
  const is_guest_ready = watch("is_guest_ready");
  const is_location_special = watch("is_location_special");
  const is_history_special = watch("is_history_special");
  const is_view_special = watch("is_view_special");
  const has_garden = watch("has_garden");
  const is_architecture_special = watch("is_architecture_special");
  const is_interior_style_special = watch("is_interior_style_special");
  const has_manager = watch("has_manager");
  const has_staff_member = watch("has_staff_member");
  const has_contact_person = watch("has_contact_person");
  const has_pool = watch("has_pool");
  const has_concierge = watch("has_concierge");
  const images = watch("images");

  const onDragEnd = (e: google.maps.MapMouseEvent) => {
    const coordinates = e.latLng;
    if (coordinates) {
      setCenter({
        lng: coordinates?.lng(),
        lat: coordinates?.lat(),
      });
    }
  };

  const onSubmit = async (data: HouseApplicationForm) => {
    const houseApplicationData: ApplicationFormData = {
      ...data,
      token: token as string,
      longitude: center?.lng ?? null,
      latitude: center?.lat ?? null,
    };
    const response = await sendHouseApplication(houseApplicationData);
    if (response.ok) {
      toast.success("Your application has been sent");
    } else {
      toast.error("Failed to send the application.");
    }
  };

  const setFormInitialData = async (token: string) => {
    const response = await retrieveOwnerInvitation(token);
    if (response.ok) {
      const { email, phone_number } = response.parsedBody;
      setValue("email", email);
      setValue("phone_number", phone_number);
    } else {
      navigate(MAIN_PAGE);
    }
  };

  const setHouseOwnerInitialData = () => {
    profile?.profileInfo?.email &&
      setValue("email", profile?.profileInfo?.email);
    profile?.profileInfo?.profile.phone_number &&
      setValue("phone_number", profile.profileInfo.profile.phone_number);
  };

  const handleAddressSelect = async () => {
    if (addressRef.current) {
      const place = autoComplete?.getPlace();
      if (place) {
        setCenter({
          lng:
            parseFloat(place.geometry?.location!.lng().toFixed(5) ?? "") ||
            defaultCenter.lng,
          lat:
            parseFloat(place.geometry?.location!.lat().toFixed(5) ?? "") ||
            defaultCenter.lat,
        });
        if (place?.address_components)
          for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
              case "postal_code": {
                setValue("zip_code", component.long_name ?? "");
                break;
              }

              case "route": {
                setValue("street", component.long_name ?? "");
                break;
              }

              case "locality": {
                setValue("city", component.long_name ?? "");
                break;
              }

              case "street_number": {
                setValue("street_no", component.long_name ?? "");
                break;
              }

              case "country": {
                setValue("country", component.long_name ?? "");
                break;
              }
            }
          }
      }
    }
  };

  const handleImagesChange = (images: FileList) => {
    setValue("images", images);
  };

  useEffect(() => {
    if (token) {
      setFormInitialData(token);
    } else {
      setHouseOwnerInitialData();
    }
    const loadGoogleMaps = async () => {
      autoComplete = await googleAutoComplete.initAutoComplete(
        addressRef.current,
        handleAddressSelect
      );
    };
    loadGoogleMaps();
  }, []);

  useEffect(() => {
    if (isBasicUserType) {
      navigate(HOUSES_PAGE);
    }
  }, [profile]);

  return (
    <Layout useBackgroundImage>
      <StyledContainer>
        <Typography variant="h1">
          {translation["applyNewHouse"][selectedLanguage]}
        </Typography>
        <Typography variant="caption">
          {translation["getFirstImpression"][selectedLanguage]}
        </Typography>
        <StyledFormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContentContainer
              display="grid"
              gridGap={3}
              justifyContent="start"
            >
              <Typography variant="h4">
                {translation["objectAddress"][selectedLanguage]}
              </Typography>
              <Input
                {...register("street")}
                ref={addressRef}
                label={translation["street"][selectedLanguage]}
                error={!isValidForm(errors) && errors.street?.message}
              />
              <Box display="grid" gridAutoFlow="column" gridGap={2.5}>
                <Input
                  {...register("street_no")}
                  label="No.*"
                  error={!isValidForm(errors) && errors.street_no?.message}
                />
                <Input
                  {...register("zip_code")}
                  label="ZIP Code*"
                  error={!isValidForm(errors) && errors.zip_code?.message}
                />
              </Box>
              <Input
                {...register("city")}
                label={translation["city"][selectedLanguage]}
                error={!isValidForm(errors) && errors.city?.message}
              />
              <Input
                {...register("country")}
                label={translation["country"][selectedLanguage]}
                error={!isValidForm(errors) && errors.country?.message}
              />
              <Map center={center} onMarkerDragEnd={onDragEnd} />
              <Typography variant="h4">
                {translation["typeSize"][selectedLanguage]}
              </Typography>
              {!isValidForm(errors) && errors.house_type?.message && (
                <Label error>{errors.house_type?.message}</Label>
              )}
              <Box display="grid" gridAutoFlow="column" justifyContent="start">
                <Radio
                  {...register("house_type")}
                  type="radio"
                  value="House"
                  label={translation["house"][selectedLanguage]}
                  selectedValue={house_type}
                />
                <Radio
                  {...register("house_type")}
                  type="radio"
                  value="Apartment"
                  label={translation["apartment"][selectedLanguage]}
                  selectedValue={house_type}
                />
              </Box>
              {!isValidForm(errors) && errors.house_size?.message && (
                <Label error>{errors.house_size?.message}</Label>
              )}
              <SizeContainer
                display="grid"
                gridGap={1.5}
                justifyContent="start"
              >
                <Radio
                  {...register("house_size")}
                  type="radio"
                  value="<100qm"
                  label="<100m²"
                  selectedValue={house_size}
                />
                <Radio
                  {...register("house_size")}
                  type="radio"
                  value="100-200qm"
                  label="100-200m²"
                  selectedValue={house_size}
                />
                <Radio
                  {...register("house_size")}
                  type="radio"
                  value=">200qm"
                  label=">200m²"
                  selectedValue={house_size}
                />
              </SizeContainer>
              <Input
                {...register("bedrooms")}
                type="number"
                label={translation["numberOfBedrooms"][selectedLanguage]}
                error={!isValidForm(errors) && errors.bedrooms?.message}
              />
              <Input
                {...register("guests")}
                type="number"
                label={translation["numberOfGuestsMax"][selectedLanguage]}
                error={!isValidForm(errors) && errors.guests?.message}
              />
              <Typography variant="h4">
                {translation["houseApartmentSpecial"][selectedLanguage]}
              </Typography>
              {!isValidForm(errors) && errors.is_location_special?.message && (
                <Label error>{errors.is_location_special?.message}</Label>
              )}
              <CheckboxContainer
                display="grid"
                gridGap={1.5}
                justifyContent="start"
              >
                <Checkbox
                  {...register("is_location_special")}
                  label={translation["location"][selectedLanguage]}
                  checked={is_location_special}
                />
                <Checkbox
                  {...register("is_history_special")}
                  label={translation["history"][selectedLanguage]}
                  checked={is_history_special}
                />
                <Checkbox
                  {...register("is_view_special")}
                  label={translation["view"][selectedLanguage]}
                  checked={is_view_special}
                />
                <Checkbox
                  {...register("has_garden")}
                  label={translation["garden"][selectedLanguage]}
                  checked={has_garden}
                />
                <Checkbox
                  {...register("is_architecture_special")}
                  label={translation["architecture"][selectedLanguage]}
                  checked={is_architecture_special}
                />
                <Checkbox
                  {...register("is_interior_style_special")}
                  label={translation["interiorStyle"][selectedLanguage]}
                  checked={is_interior_style_special}
                />
                <Checkbox
                  {...register("has_pool")}
                  label={translation["pool"][selectedLanguage]}
                  checked={has_pool}
                />
              </CheckboxContainer>
              <Typography variant="h4">
                {translation["description"][selectedLanguage]}
              </Typography>
              <Textarea
                {...register("description")}
                type="text"
                error={!isValidForm(errors) && errors.description?.message}
              />
              <Typography variant="h4">
                {translation["rentOnOtherPlatforms"][selectedLanguage]}
              </Typography>
              {!isValidForm(errors) &&
                errors.is_on_other_platforms?.message && (
                  <Label error>{errors.is_on_other_platforms?.message}</Label>
                )}
              <Box
                display="grid"
                gridAutoFlow="column"
                gridGap={1.5}
                justifyContent="start"
              >
                <Radio
                  {...register("is_on_other_platforms")}
                  type="radio"
                  value="Yes"
                  label={translation["yes"][selectedLanguage]}
                  selectedValue={is_on_other_platforms}
                />
                <Radio
                  {...register("is_on_other_platforms")}
                  type="radio"
                  value="No"
                  label={translation["no"][selectedLanguage]}
                  selectedValue={is_on_other_platforms}
                />
              </Box>
              <Typography variant="h4">
                {translation["guestReady"][selectedLanguage]}
              </Typography>
              {!isValidForm(errors) && errors.is_guest_ready?.message && (
                <Label error>{errors.is_guest_ready?.message}</Label>
              )}
              <Box
                display="grid"
                gridAutoFlow="column"
                gridGap={1.5}
                justifyContent="start"
              >
                <Radio
                  {...register("is_guest_ready")}
                  type="radio"
                  value="Yes"
                  label={translation["yes"][selectedLanguage]}
                  selectedValue={is_guest_ready}
                />
                <Radio
                  {...register("is_guest_ready")}
                  type="radio"
                  value="No"
                  label={translation["no"][selectedLanguage]}
                  selectedValue={is_guest_ready}
                />
              </Box>
              <Typography variant="h4">
                {translation["houseApartmentCare"][selectedLanguage]}
              </Typography>
              {!isValidForm(errors) && errors.has_concierge?.message && (
                <Label error>{errors.has_concierge?.message}</Label>
              )}
              <Checkbox
                {...register("has_concierge")}
                label={translation["agencyConciergeService"][selectedLanguage]}
                checked={has_concierge}
              />
              <Checkbox
                {...register("has_manager")}
                type="checkbox"
                label={translation["propertyManager"][selectedLanguage]}
                checked={has_manager}
              />
              <Checkbox
                {...register("has_staff_member")}
                type="checkbox"
                label={translation["staffMember"][selectedLanguage]}
                checked={has_staff_member}
              />
              <Checkbox
                {...register("has_contact_person")}
                type="checkbox"
                label={translation["contactPerson"][selectedLanguage]}
                checked={has_contact_person}
              />
              <Typography variant="h4">
                {translation["uploadPhotos"][selectedLanguage]}
              </Typography>
              <MultiImageInput
                {...register("images")}
                images={images}
                handleChange={handleImagesChange}
              />
              <Typography variant="h4">
                {translation["contactDetails"][selectedLanguage]}
              </Typography>
              <PhoneInput
                id="phone_number"
                control={control}
                label={translation["phoneNumber"][selectedLanguage]}
              />
              <Input
                {...register("email")}
                label="email"
                type="email"
                disabled
                error={!isValidForm(errors) && errors.email?.message}
              />
              <ButtonContainer>
                <Button type="submit" size={ButtonSize.SMALL}>
                  {translation["applyYourHouse"][selectedLanguage]}
                </Button>
              </ButtonContainer>
            </StyledContentContainer>
          </form>
        </StyledFormContainer>
      </StyledContainer>
    </Layout>
  );
};

export default HouseApplication;
