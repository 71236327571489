import { FC, useState, useEffect, useRef } from "react";
import Layout from "components/Layout";
import {
  fetchFriendInvitationToken,
  fetchSendEmailInvitation,
} from "services/Invitation";
import { useProfile } from "context/Profile";
import { Breadcrumbs } from "components/Breadcrumbs";
import {
  MainContainer,
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilterLink,
  StyledInviteButtonContainer,
  StyledSubtitle,
  Header,
  SelectContainer,
  SelectedOptionsContainer,
  HeaderContainer,
  FilersContainer,
  FilterBox,
  StyledLabel,
  StyledDropdown,
  StyledSelectArrowIcon,
  StyledSelectContainer,
  StyledOptionsContainer,
  StyledSortSelect,
  StyledCheckbox,
} from "./Friends.styled";
import { FriendsList } from "components/Friends/FriendsList/FriendsList";
import { fetchFriends, fetchUserInfo } from "services/Friends";
import {
  Friend,
  FriendEmailInvitation,
  FriendLimitedInfo,
  FriendsProps,
  EditableFriendOptions,
} from "types/friend.d";
import { DiscountOption } from "types/discount.d";
import { FriendCardVariant } from "components/Friends/FriendCard/FriendCard.enums";
import {
  fetchSendInviteToConnections,
  fetchAcceptConnectionInvitation,
  fetchDeclineConnectionInvitation,
  fetchDeactivateConnectionInvitation,
  fetchDeactivateEmailInvitation,
  fetchDeleteFromFriends,
  fetchUpdateFriendOptions,
  fetchUpdateEmailInvitation,
} from "services/Friends";
import { toast } from "react-toastify";
import { Box, Typography } from "components";
import { COLORS } from "theme";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { ReactComponent as PlusIcon } from "assets/icons/icon_plus.svg";
import { useModal } from "global/hooks/useModal";
import { InviteFriendViaEmailModal } from "components/Modals/InviteFriendViaEmailModal";
import { fetchDiscountList } from "services/Discount";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserProfilePreviewModal } from "components/Modals/UserProfilePreviewModal";
import { ConnectionsSearch } from "components/Friends/ConnectionsSearch";
import { AccessVariant } from "components/Modals/InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";
import { EmailLanguageOption } from "types/emailLanguage";
import { Loader } from "components/Loader/Loader";
import { useForm } from "react-hook-form";
import { Checkbox } from "components/Checkbox";
import { FriendsFilter } from "./types";
import FriendChip from "components/Chip/FriendChip";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useHandleClickOutside } from "global/hooks/useHandleClickOutside";
import { ReactComponent as DropdownIcon } from "assets/icons/icon_dropdown_menu.svg";
import { Option } from "components/Select/Select.d";
import QRCodeBoxes from "components/QRCodeBoxes/QRCodeBoxes";
import { HOUSES_PAGE } from "urls/frontend";

export const Friends: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const [friends, setFriends] = useState<FriendsProps>();
  const [userInfo, setUserInfo] = useState<FriendLimitedInfo | null>(null);
  const [invitationToken, setInvitationToken] = useState<string>("");
  const [selectedSortOption, setSelectedSortOption] = useState<Option>({
    name: "",
    value: "",
  });
  const profile = useProfile();
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";
  const profileInfo = profile?.profileInfo;
  const { isShown, toggle } = useModal();
  const [isShownUserProfilePreviewModal, setIsShownUserProfileModal] =
    useState<boolean>(false);
  const inviteModalRef = useRef<HTMLDivElement | null>(null);
  const [open, setIsOpen] = useState(false);
  const anchor = useRef(null);
  useHandleClickOutside(anchor, () => setIsOpen(false));
  const [discountOptions, setDiscountOptions] = useState<DiscountOption[]>([
    { name: "", rate: "", description: "" },
  ]);
  const emailLanguageOptions: EmailLanguageOption[] = [
    { language: "ENGLISH" },
    { language: "GERMAN" },
    { language: "FRENCH" },
  ];
  const [activeUserId, setActiveUserId] = useState<string>("");
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [findFriendCardVariant] =
    Object.entries(FriendCardVariant).find(
      ([, variant]) => variant === filter
    ) ?? [];
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const options: Array<Option> = [
    {
      name: translation["sortByAlphabetically"][selectedLanguage],
      value: "alpha",
    },
    {
      name: translation["sortByMutual"][selectedLanguage],
      value: "mutual_relations",
    },
    { name: translation["sortByDate"][selectedLanguage], value: "date_joined" },
  ];

  const { register, watch, setValue } = useForm<FriendsFilter>({
    shouldUseNativeValidation: true,
  });

  const trusted_friend = watch("trusted_friend");
  const invited_by_me = watch("invited_by_me");
  const house_owner = watch("house_owner");
  const family_discount = watch("family_discount");
  const old_friend = watch("old_friend");
  const no_discount = watch("no_discount");

  const showActiveFilters = () =>
    trusted_friend ||
    invited_by_me ||
    house_owner ||
    family_discount ||
    old_friend ||
    no_discount;

  const getToken = async () => {
    const response = await fetchFriendInvitationToken();
    if (response.ok) {
      setInvitationToken(response.parsedBody.token);
    }
  };

  const getFriends = async () => {
    const discountFilters = [
      family_discount ? "FAMILY_DISCOUNT" : "",
      old_friend ? "OLD_FRIEND_DISCOUNT" : "",
      no_discount ? "NO_DISCOUNT" : "",
    ];
    const userTypesFilters = [
      trusted_friend ? "trusted-friend" : "",
      invited_by_me ? "invited-by-me" : "",
      house_owner ? "house-owner" : "",
    ];

    const discountActiveFilters = discountFilters.filter(Boolean);
    const userTypeActiveFilters = userTypesFilters.filter(Boolean);

    const discountFiltersString =
      discountActiveFilters.length > 0
        ? `${discountActiveFilters.join(",")}`
        : "";

    const userTypeFiltersString =
      userTypeActiveFilters.length > 0
        ? `${userTypeActiveFilters.join(",")}`
        : "";

    const response = await fetchFriends(
      filter,
      discountFiltersString,
      userTypeFiltersString,
      selectedSortOption.value
    );
    response && setFriends(response?.parsedBody);
    setIsLoaded(true);
  };

  const getUserInfo = async () => {
    const response = await fetchUserInfo(activeUserId);
    response.ok && setUserInfo(response.parsedBody);
  };

  const getDiscountOptions = async () => {
    const response = await fetchDiscountList();
    response && setDiscountOptions(response?.parsedBody);
  };

  const friendsFilters = [
    trusted_friend,
    invited_by_me,
    house_owner,
    family_discount,
    old_friend,
    no_discount,
  ];

  useEffect(() => {
    getDiscountOptions();
    if (profileInfo?.can_add_friends) {
      getToken();
    }
  }, []);

  useEffect(() => {
    getFriends();
  }, [...friendsFilters, searchParams, selectedSortOption]);

  useEffect(() => {
    activeUserId && getUserInfo();
  }, [activeUserId]);

  useEffect(() => {
    if (isBasicUserType) navigate(HOUSES_PAGE);
  }, []);

  const activeFiltersCount = friendsFilters.reduce(
    (accumulator, currentValue) => {
      if (currentValue === true) {
        return accumulator + 1;
      } else {
        return accumulator;
      }
    },
    0
  );

  const declineInvitation = async (friendId: string, invitationId: string) => {
    const data = {
      invited_user: friendId,
    };
    const response = await fetchDeclineConnectionInvitation(data, invitationId);
    if (response.ok) {
      toast.success(translation["invitationDeclined"][selectedLanguage]);
      getFriends();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["invitationDeclineError"][selectedLanguage]);
    }
  };

  const deactivateConnectionInvitation = async (invitationId: string) => {
    const response = await fetchDeactivateConnectionInvitation(invitationId);
    if (response?.ok) {
      toast.success(translation["invitationDeactivated"][selectedLanguage]);
      getFriends();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["invitationDeactivationError"][selectedLanguage]);
    }
  };

  const deactivateEmailInvitation = async (email: string) => {
    const response = await fetchDeactivateEmailInvitation({
      email,
    });
    if (response?.ok) {
      toast.success(translation["invitationDeactivated"][selectedLanguage]);
      getFriends();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["invitationDeactivationError"][selectedLanguage]);
    }
  };

  const deleteFromFriends = async (friendId: string) => {
    const response = await fetchDeleteFromFriends(friendId);
    if (response.ok) {
      toast.success(
        toast.success(translation["friendDeleted"][selectedLanguage])
      );
      getFriends();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["deletingError"][selectedLanguage]);
    }
  };

  const sendInvitation = async (friendId: string) => {
    const data = {
      invited_user: friendId,
    };
    const response = await fetchSendInviteToConnections(data);
    if (response.ok) {
      toast.success(translation["requestSent"][selectedLanguage]);
      getFriends();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["sendingError"][selectedLanguage]);
    }
  };

  const sendInvitationReminder = async (friendData: Friend, email: string) => {
    const [findAccessVariant] =
      Object.entries(AccessVariant).find(
        ([, variant]) => variant === friendData.access_type
      ) ?? [];
    const data: FriendEmailInvitation = {
      email,
      name: friendData.name,
      last_name: friendData.last_name,
      access_type:
        AccessVariant[findAccessVariant as keyof typeof AccessVariant],
      discount: friendData.discount,
    };
    const response = await fetchSendEmailInvitation(data);
    if (response.ok) {
      toast.success(translation["reminderSent"][selectedLanguage]);
    } else {
      toast.error(translation["errorSendingReminder"][selectedLanguage]);
    }
  };

  const acceptInvitation = async (friendId: string, invitationId: string) => {
    const data = {
      invited_user: friendId,
    };
    const response = await fetchAcceptConnectionInvitation(data, invitationId);
    if (response.ok) {
      toast.success(translation["invitationAccepted"][selectedLanguage]);
      getFriends();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["invitationAcceptError"][selectedLanguage]);
    }
  };

  const updateFriendOptions = async (
    data: EditableFriendOptions,
    friendId: string
  ) => {
    const response = await fetchUpdateFriendOptions(data, friendId);
    if (response.ok) {
      toast.success(translation["updateSendSuccess"][selectedLanguage]);
      getUserInfo();
    } else {
      toast.error(translation["updateError"][selectedLanguage]);
    }
  };

  const updateEmailInvitation = async (
    data: EditableFriendOptions,
    invitationId: string
  ) => {
    const response = await fetchUpdateEmailInvitation(data, invitationId);
    if (response.ok) {
      toast.success(translation["updateSendSuccess"][selectedLanguage]);
      getUserInfo();
    } else {
      toast.error(translation["updateError"][selectedLanguage]);
    }
  };

  const getFriendsNumberInCategory = (category: FriendCardVariant) =>
    friends?.filters.find((filter) => filter.name === category)?.counter ?? 0;

  const filters = [
    {
      name: translation["friends"][selectedLanguage],
      id: "4",
      icon: "",
      isActive: filter === FriendCardVariant.CONNECTIONS,
      handleClick: () => {
        searchParams.set("filter", FriendCardVariant.CONNECTIONS);
        setSearchParams(searchParams);
      },
      number: getFriendsNumberInCategory(FriendCardVariant.CONNECTIONS),
    },
    {
      name: translation["requestsSent"][selectedLanguage],
      id: "3",
      icon: "",
      isActive: filter === FriendCardVariant.REQUESTS_SENT,
      handleClick: () => {
        searchParams.set("filter", FriendCardVariant.REQUESTS_SENT);
        setSearchParams(searchParams);
      },
      number: getFriendsNumberInCategory(FriendCardVariant.REQUESTS_SENT),
    },
    {
      name: translation["requestsReceived"][selectedLanguage],
      id: "2",
      icon: "",
      isActive: filter === FriendCardVariant.REQUESTS_RECEIVED,
      handleClick: () => {
        searchParams.set("filter", FriendCardVariant.REQUESTS_RECEIVED);
        setSearchParams(searchParams);
      },
      number: getFriendsNumberInCategory(FriendCardVariant.REQUESTS_RECEIVED),
    },
  ];

  const openUserPreviewModal = (id: string) => {
    setActiveUserId(id);
    setIsShownUserProfileModal(true);
  };

  const closeUserProfilePreviewModal = () => {
    setActiveUserId("");
    setUserInfo(null);
    setIsShownUserProfileModal(false);
  };

  return (
    <Layout>
      {isLoaded ? (
        <MainContainer variant="div" mx={2.2} my={4}>
          <Breadcrumbs currentPageTitle="Friends" />
          <HeaderContainer display="grid" mt={5} mb={1.2}>
            <Header variant="h1">
              {translation["myFriends"][selectedLanguage]}
            </Header>
            <StyledOptionsContainer>
              {filter === "connections" && (
                <FilersContainer display="grid" alignItems="center" gridGap={4}>
                  {showActiveFilters() && (
                    <SelectedOptionsContainer
                      display="grid"
                      gridAutoFlow="column"
                    >
                      {trusted_friend && (
                        <FriendChip
                          label="Trusted friend"
                          handleClick={() => setValue("trusted_friend", false)}
                        />
                      )}
                      {invited_by_me && (
                        <FriendChip
                          label="Invited by me"
                          handleClick={() => setValue("invited_by_me", false)}
                        />
                      )}
                      {house_owner && (
                        <FriendChip
                          label="House Owner"
                          handleClick={() => setValue("house_owner", false)}
                        />
                      )}
                      {no_discount && (
                        <FriendChip
                          label="No Discount"
                          handleClick={() => setValue("no_discount", false)}
                        />
                      )}
                      {old_friend && (
                        <FriendChip
                          label="Old Friend"
                          handleClick={() => setValue("old_friend", false)}
                        />
                      )}
                      {family_discount && (
                        <FriendChip
                          label="Family Discount"
                          handleClick={() => setValue("family_discount", false)}
                        />
                      )}
                    </SelectedOptionsContainer>
                  )}
                  <SelectContainer ref={anchor} display="grid">
                    <FilterBox onClick={() => setIsOpen(!open)} isOpen={open}>
                      <DropdownIcon />
                      <StyledLabel>
                        Filters{" "}
                        {activeFiltersCount > 0
                          ? ` (${activeFiltersCount})`
                          : ""}
                      </StyledLabel>
                      <StyledSelectArrowIcon isOpen={open} />
                    </FilterBox>
                    <StyledDropdown
                      orientation="left"
                      open={open}
                      isOpen={open}
                      anchorEl={anchor}
                      onClose={() => setIsOpen(false)}
                    >
                      <Box display="grid" gridGap={1.8}>
                        <Typography variant="body">
                          {translation["userType"][selectedLanguage]}
                        </Typography>
                        <StyledCheckbox
                          {...register("invited_by_me", {
                            required:
                              translation["requiredField"][selectedLanguage],
                          })}
                          checked={invited_by_me}
                          label={translation["invitedByMe"][selectedLanguage]}
                        />
                        <StyledCheckbox
                          {...register("trusted_friend", {
                            required:
                              translation["requiredField"][selectedLanguage],
                          })}
                          checked={trusted_friend}
                          label={translation["trustedFriend"][selectedLanguage]}
                        />
                        <StyledCheckbox
                          {...register("house_owner", {
                            required:
                              translation["requiredField"][selectedLanguage],
                          })}
                          checked={house_owner}
                          label={translation["houseOwner"][selectedLanguage]}
                        />
                        <Typography variant="body">
                          {translation["discountType"][selectedLanguage]}
                        </Typography>
                        <StyledCheckbox
                          {...register("no_discount", {
                            required:
                              translation["requiredField"][selectedLanguage],
                          })}
                          checked={no_discount}
                          label="0%"
                        />
                        <StyledCheckbox
                          {...register("old_friend", {
                            required:
                              translation["requiredField"][selectedLanguage],
                          })}
                          checked={old_friend}
                          label={
                            translation["oldFriend10Percent"][selectedLanguage]
                          }
                        />
                        <StyledCheckbox
                          {...register("family_discount", {
                            required:
                              translation["requiredField"][selectedLanguage],
                          })}
                          checked={family_discount}
                          label={
                            translation["family20Percent"][selectedLanguage]
                          }
                        />
                      </Box>
                    </StyledDropdown>
                  </SelectContainer>
                </FilersContainer>
              )}
              <StyledSelectContainer>
                <StyledSortSelect
                  options={options}
                  labelInside={translation["sortBy"][selectedLanguage]}
                  value={selectedSortOption}
                  onChange={(option) => setSelectedSortOption(option)}
                />
              </StyledSelectContainer>
            </StyledOptionsContainer>
          </HeaderContainer>
          <StyledContentContainer
            display="grid"
            alignItems="start"
            gridGap={2}
            pt={2.2}
          >
            <Box display="grid" gridGap={5.5}>
              {profileInfo?.can_add_friends && (
                <Box display="grid" gridGap={2.5}>
                  <StyledSubtitle variant="h4">
                    {translation["inviteFriendViaEmail"][selectedLanguage]}
                  </StyledSubtitle>
                  <StyledInviteButtonContainer>
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      icon={<PlusIcon />}
                      onClick={toggle}
                    >
                      {translation["addFriendViaEmail"][selectedLanguage]}
                    </Button>
                  </StyledInviteButtonContainer>
                </Box>
              )}
              <ConnectionsSearch
                menuOpen={searchOpen}
                setMenuOpen={setSearchOpen}
                sendInvitation={sendInvitation}
                onFriendCardClick={openUserPreviewModal}
              />
              <StyledFiltersContainer
                px={2.75}
                py={4}
                display="grid"
                gridGap={2.5}
              >
                {filters.map((filter) => (
                  <Box
                    key={filter.id}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <StyledFilterLink
                      variant="link"
                      isActive={filter.isActive}
                      onClick={filter.handleClick}
                    >
                      {filter.icon}
                      {filter.name}
                    </StyledFilterLink>
                    <Typography variant="h4" color={COLORS.stroke.focus}>
                      {filter.number}
                    </Typography>
                  </Box>
                ))}
              </StyledFiltersContainer>
            </Box>
            <FriendsList
              variant={
                FriendCardVariant[
                  findFriendCardVariant as keyof typeof FriendCardVariant
                ]
              }
              friends={friends?.data ?? []}
              declineInvitation={declineInvitation}
              deactivateConnectionInvitation={deactivateConnectionInvitation}
              deactivateEmailInvitation={deactivateEmailInvitation}
              deleteFromFriends={deleteFromFriends}
              sendInvitation={sendInvitation}
              acceptInvitation={acceptInvitation}
              onFriendCardClick={openUserPreviewModal}
              sendInvitationReminder={sendInvitationReminder}
            />
          </StyledContentContainer>
        </MainContainer>
      ) : (
        <Loader />
      )}
      {!isBasicUserType && <QRCodeBoxes token={invitationToken} />}
      {profileInfo?.can_add_friends && (
        <InviteFriendViaEmailModal
          ref={inviteModalRef}
          isShown={isShown}
          closeModal={toggle}
          discountOptions={discountOptions}
          emailLanguageOptions={emailLanguageOptions}
        />
      )}
      {userInfo && (
        <UserProfilePreviewModal
          variant={
            searchOpen
              ? FriendCardVariant.PEOPLE_YOU_MIGHT_KNOW
              : FriendCardVariant[
                  findFriendCardVariant as keyof typeof FriendCardVariant
                ]
          }
          userInfo={userInfo}
          discountOptions={discountOptions}
          isShown={isShownUserProfilePreviewModal}
          closeModal={closeUserProfilePreviewModal}
          declineInvitation={declineInvitation}
          deactivateConnectionInvitation={deactivateConnectionInvitation}
          deactivateEmailInvitation={deactivateEmailInvitation}
          deleteFromFriends={deleteFromFriends}
          sendInvitation={sendInvitation}
          sendInvitationReminder={sendInvitationReminder}
          acceptInvitation={acceptInvitation}
          updateFriendOptions={updateFriendOptions}
          updateEmailInvitation={updateEmailInvitation}
        />
      )}
    </Layout>
  );
};
