import styled from "styled-components";
import { Typography } from "components";
import { SIZES } from "theme";
import MobileBackgroundImage from "assets/qr-code-images/qr_code_image_mobile.png";
import TabletBackgroundImage from "assets/qr-code-images/qr_code_image_tablet.png";
import DesktopBackgroundImage from "assets/qr-code-images/qr_code_image_desktop.png";
import { pxToRem } from "global/utils";

export const StyledHeaderContainer = styled.section`
  position: relative;
  height: 248px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: ${pxToRem(40)};

  background-image: url(${MobileBackgroundImage});
  background-size: 100% 100%;
  min-height: ${pxToRem(376)};

  ${SIZES.media.tablet} {
    min-height: initial;
    background-image: url(${TabletBackgroundImage});
  }

  ${SIZES.media.desktop} {
    background-image: url(${DesktopBackgroundImage});
  }
`;

export const StyledHeader = styled(Typography)`
  width: 50%;
  text-align: center;
  letter-spacing: 0.3px;

  ${SIZES.media.tablet} {
    width: 17%;
  }
`;
