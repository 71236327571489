import { Box } from "components/Box";
import { Breadcrumbs } from "components/Breadcrumbs";
import { HousesList } from "components/HousesList";
import { HouseItem } from "components/HousesList/HousesList.d";
import Layout from "components/Layout";
import { useProfile } from "context/Profile";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchFavoritesHouses } from "services/Houses";
import { fetchFriendInvitationToken } from "services/Invitation";
import {
  HeaderContainer,
  MainContainer,
  StyledQRCodeBoxes,
} from "./Favorites.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { HOUSES_PAGE } from "urls/frontend";

export const Favorites: FC = () => {
  const [houses, setHouses] = useState<HouseItem[]>([]);
  const [searchParams] = useSearchParams();
  const [invitationToken, setInvitationToken] = useState<string>("");
  const profile = useProfile();
  const profileInfo = profile?.profileInfo;
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";
  const navigate = useNavigate();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const getToken = async () => {
    const response = await fetchFriendInvitationToken();
    if (response.ok) {
      setInvitationToken(response.parsedBody.token);
    }
  };
  const getHouses = async () => {
    const response = await fetchFavoritesHouses();
    response && setHouses(response.parsedBody.houses);
  };
  useEffect(() => {
    getHouses();
  }, [searchParams]);

  useEffect(() => {
    if (profileInfo?.can_add_friends) {
      getToken();
    }
  }, [profileInfo]);

  useEffect(() => {
    if (isBasicUserType) navigate(HOUSES_PAGE);
  }, []);

  return (
    <Layout>
      <MainContainer variant="div" mb={2.25}>
        <Breadcrumbs currentPageTitle="Favorites" />
        <HeaderContainer variant="h1">
          {translation["favorites"][selectedLanguage]}
        </HeaderContainer>
        <HousesList houses={houses} />
        {profileInfo?.can_add_friends && (
          <Box mt={3}>
            <StyledQRCodeBoxes token={invitationToken} />
          </Box>
        )}
      </MainContainer>
    </Layout>
  );
};
